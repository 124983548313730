// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

*,
ion-label {
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif !important;
  line-height: 1.5;
  // color: #4a4a4a;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-title,
ion-menu-toggle ion-label {
  font-family: 'Cinzel' !important;
  // color: #222
}

p {
  color: var(--ion-color-dark-tint);
}

:root {
  --ion-padding: 8px;
  --ion-margin: 8px;
  --ion-tab-bar-color-active: var(--ion-color-primary);

  --ion-color-primary: #466242;
  --ion-color-primary-rgb: 70, 98, 66;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3e563a;
  --ion-color-primary-tint: #597255;

  --ion-color-secondary: #4e4e77;
  --ion-color-secondary-rgb: 78, 78, 119;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #454569;
  --ion-color-secondary-tint: #606085;

  --ion-color-tertiary: #5e8f6e;
  --ion-color-tertiary-rgb: 94, 143, 110;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #537e61;
  --ion-color-tertiary-tint: #6e9a7d;

  --ion-color-success: #8dbd73;
  --ion-color-success-rgb: 141, 189, 115;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #7ca665;
  --ion-color-success-tint: #98c481;

  --ion-color-warning: #ddbe58;
  --ion-color-warning-rgb: 221, 190, 88;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c2a74d;
  --ion-color-warning-tint: #e0c569;

  --ion-color-danger: #a1243b;
  --ion-color-danger-rgb: 161, 36, 59;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #8e2034;
  --ion-color-danger-tint: #aa3a4f;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  .callout-left-icon {
    width: 28px;
    height: 28px;
  }
  .callout-left-button {
    grid-area: icon2;
    color: var(--ion-color-primary);
    padding: 3px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
  }
  .callout-title {
    color: var(--ion-color-primary);
    grid-area: title;
  }
  .callout-detail {
    grid-area: detail;
    color: var(--ion-color-secondary);
    font-size: 0.7em;
  }
  .callout-content {
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: auto;
    grid-template-areas:
      'icon2 title'
      'icon2 detail';
  }

  // form {
  // .ng-valid:not(form):not(rating):not(ion-radio-group) {
  //   border-left: 5px solid var(--ion-color-success); /* green */
  // }

  // .ng-invalid:not(form):not(rating):not(ion-radio-group) {
  //   border-left: 5px solid var(--ion-color-danger); /* red */
  // }

  // ion-input,
  // ion-textarea,

  .no-border {
    --border-style: none;
    --highlight-height: 0px;
  }

  ion-title img {
    max-height: 40px;
    width: auto;
  }

  .get-user-location {
    max-height: 400px;
  }

  .button-flush-start {
    --padding-start: 0px;
  }

  .double-padding-start {
    padding-left: 16px;
  }

  .button-padding-start {
    --padding-start: var(--ion-padding);
  }

  .imageSlideshowModal {
    .modal-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
    --background: transparent;
  }

  .filterPopover {
    --width: 500px;
    --max-width: 90%;
  }

  .sortPopover {
    --width: 270px;
    --max-width: 90%;
  }

  .placeReportAlert {
    --width: 500px;
    --max-width: 90%;
  }

  .askUserLocationPopover {
    --width: 300px;
    --max-width: 90%;
    --max-height: 60%;
  }

  .large {
    font-size: 1.2em;
  }
}

// * For debugging
// *:not(path):not(g) {
//   color: hsla(210, 100%, 100%, 0.9) !important;
//   background: hsla(210, 100%, 50%, 0.5) !important;
//   outline: solid 2px hsla(210, 100%, 100%, 0.5) !important;
//   box-shadow: none !important;
// }
